/* DM Sans */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

/* Manrope */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@import "./variable.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  overscroll-behavior: none;
}
body {
  height: 100%;
  overflow: auto;
  overscroll-behavior: none;
}

html,
body {
  font-size: 10px;
  font-family: "inter", serif;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

section {
  width: 100%;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 80px 0;
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1240px) {
  .container {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 60px 20px;
  }
}
