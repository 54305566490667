// @import "src/styles/variable.scss";
@use "styles/variable" as *;

.contact {
  cursor: pointer;

  .contactBtn {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @include d-flex($gap: 8px);
    padding: 12px 24px;
    border-radius: 36px;
    border: 1px solid $color-white-2;
    background: $color-white-0;
    transition: all 0.3s ease;

    .contactText {
      @include sans-family();
      @include font-style($fs: 16px, $fw: 450, $lh: 28px);
      color: $color-blue-0;
    }

    .animation {
      position: absolute;
      width: 30%;
      top: 0;
      bottom: 0;
      transform: skewY(35deg);
      right: 100%;
      background: $color-grd-darkBlue-0;
      filter: blur(6px);
      z-index: 0;
      transition: all 0.6s ease-out;
    }

    &:hover {
      border: 1px solid $color-blue-0;

      .animation {
        transform: translateX(500%);
      }
    }
  }
}
