// @import "src/styles/variable.scss";
@use "styles/variable" as *;

.footer {
  .footerContainer {
    @include d-flex($fd: column, $gap: 60px);
    padding: 60px 0;

    .footerContent {
      @include d-flex($jc: space-between);
      flex-wrap: wrap;

      .company {
        @include d-flex($fd: column, $gap: 20px);
        max-width: 297px;

        .logo {
          @include d-flex($fd: column, $gap: 14px);

          .imageWrapper {
            max-width: 204px;
            height: 66px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .companyText {
            p {
              color: $color-black-nav-0;
              @include sans-family();
              @include font-style($fs: 16px, $fw: 350, $lh: 28px);
            }
          }
        }
        .socials {
          @include d-flex($al: center, $gap: 16px);

          .imageWrapper {
            height: 32px;

            img {
              height: 100%;
            }
          }
        }
      }

      .service {
        @include d-flex($fd: column, $gap: 14px);

        .title {
          p {
            color: $color-black-nav-0;
            @include sans-family();
            @include font-style($fs: 18px, $fw: 450, $lh: 26px);
          }
        }
        .items {
          @include d-flex($fd: column, $gap: 12px);

          p {
            width: fit-content;
            cursor: pointer;
            color: $color-gray-0;
            @include sans-family();
            @include font-style($fs: 16px, $fw: 350, $lh: 26px);
          }
        }
      }
      .info {
        @include d-flex($fd: column, $gap: 32px);

        .contacts {
          @include d-flex($fd: column, $gap: 12px);

          .title {
            color: $color-black-nav-0;
            @include sans-family();
            @include font-style($fs: 18px, $fw: 450, $lh: 26px);
          }

          .number {
            @include d-flex($gap: 4px, $al: center);
            padding: 4px 0;
            border-bottom: 1px solid $color-gray-0;
            max-width: fit-content;
            cursor: pointer;
            transition: gap 0.3s ease;

            &:hover {
              border-bottom: 1px solid $color-green-0;
              @include d-flex($gap: 8px, $al: center);
              .numberInfo {
                color: $color-green-0;
              }
            }
            .numberInfo {
              color: $color-gray-0;
              @include sans-family();
              @include font-style($fs: 16px, $fw: 350, $lh: 20px);
            }
          }
        }
        .mail {
          @include d-flex($fd: column, $gap: 14px);
          .title {
            color: $color-black-nav-0;
            @include sans-family();
            @include font-style($fs: 18px, $fw: 450, $lh: 26px);
          }
          .mailId {
            @include d-flex($gap: 4px, $al: center);
            min-width: 265px;
            padding: 4px 0;
            border-bottom: 1px solid $color-gray-0;
            transition: gap 0.3s ease;

            &:hover {
              border-bottom: 1px solid $color-green-0;
              @include d-flex($gap: 8px, $al: center);
              .mailIdInfo {
                color: $color-green-0;
              }
            }
            .mailIdInfo {
              color: $color-gray-0;
              @include sans-family();
              @include font-style($fs: 16px, $fw: 350, $lh: 20px);
              cursor: pointer;
            }
          }
        }
      }
      .address {
        max-width: 290px;
        @include d-flex($fd: column, $gap: 12px);

        .addressInfo {
          @include d-flex($fd: column, $gap: 14px);

          .title {
            color: $color-black-nav-0;
            @include font-style($fs: 18px, $fw: 450, $lh: 26px);
            @include sans-family();
          }

          .content {
            color: $color-gray-0;
            @include sans-family();
            @include font-style($fs: 16px, $fw: 350, $lh: 26px);
          }
        }
        .direction {
          max-width: fit-content;
          .directionContainer {
            @include d-flex($gap: 4px, $al: center);
            padding: 4px 0;
            border-bottom: 1px solid $color-gray-0;
            max-width: fit-content;
            cursor: pointer;
            transition: gap 0.3s ease;

            &:hover {
              border-bottom: 1px solid $color-green-0;
              @include d-flex($gap: 8px, $al: center);
              .directionText {
                color: $color-green-0;
              }
            }

            .directionText {
              color: $color-gray-0;
              @include sans-family();
              @include font-style($fs: 16px, $fw: 350, $lh: 20px);
            }

            img {
              &:hover {
                fill: $color-green-0;
              }
            }
          }
        }
      }
    }
    .copyRight {
      @include d-flex($jc: space-between, $al: center);
      border-top: 1px solid $color-gray-1;
      padding-top: 24px;

      .companyName {
        p {
          color: $color-gray-0;
          @include sans-family();
          @include font-style($fs: 16px, $fw: 250, $lh: 28px);
        }
      }
      .termsContainer {
        @include d-flex($gap: 24px);

        .termsAndPolicy {
          cursor: pointer;
          color: $color-gray-0;
          @include sans-family();
          @include font-style($fs: 16px, $fw: 250, $lh: 28px);
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .footer {
    .footerContainer {
      .footerContent {
        row-gap: 40px;
        column-gap: 60px;
        .company {
          min-width: 100%;
        }
        .info {
          .mail {
            .mailId {
              width: fit-content;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .footer {
    .footerContainer {
      .footerContent {
        display: flex;
        justify-content: initial;
        row-gap: 40px;
        column-gap: 60px;

        .company {
          max-width: unset;
        }
      }
      .copyRight {
        flex-wrap: wrap;
        .termsContainer {
          flex-wrap: wrap;
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .footer {
    .footerContainer {
      @include d-flex($fd: column, $gap: 40px);
      padding: 60px 20px;

      .footerContent {
        @include d-flex($fd: column, $gap: 40px);

        .company {
          max-width: 335px;

          .logo {
            .imageWrapper {
              max-width: 140px;
              height: 46px;
            }
          }
        }
        .service {
          .items {
            p {
              width: fit-content;
            }
          }
        }
        .info {
          .contact {
            @include d-flex($fd: column, $gap: 14px);
          }
          .mail {
            .mailId {
              width: fit-content;
            }
          }
        }
      }
      .copyRight {
        @include d-flex($fd: column, $gap: 20px);
      }
    }
  }
}
