$color-black-nav-0: #0e0e0f;
$color-black-1: rgba(0, 0, 0, 0.06);
$color-black-2: #000;
$color-black-3: #0e0d17;
$color-black-4: rgba(0, 0, 0, 0.2);

$color-green-0: #45c1c5;

$color-gray-0: #4c4d52;
$color-gray-1: rgba(82, 84, 89, 0.2);
$color-gray-2: #95979d;

$color-blue-0: #1f396c;

$color-white-0: #fff;
$color-white-1: #e9ebf0;
$color-white-2: #d0ddf4;
$color-white-3: #ccd7e2;
$color-white-4: #f4f5f6;
$color-white-5: #cfd0d2;
$color-white-6: #fafafa;

$color-lightBlue-0: #f1f8ff;

$color-red-0-error: #ed4337;

$color-grd-darkBlue-0: linear-gradient(
  90deg,
  rgba(31, 57, 108, 0) 0%,
  rgba(31, 57, 108, 0.24) 50%,
  rgba(31, 57, 108, 0) 100%
);

$color-grd-lightBlue-0: linear-gradient(
  to top,
  rgba(173, 197, 236, 0.7) 30%,
  rgba(173, 197, 236, 0) 44%
);
$color-grd-lightBlue-1: linear-gradient(
  rgba(100, 150, 250, 0.7),
  rgba(100, 150, 250, 0)
);
$color-grd-lightBlue-2: linear-gradient(
  to top,
  rgba(173, 197, 236, 0.7),
  rgba(173, 197, 236, 0)
);
$color-grd-lightBlue-3: linear-gradient(
  to top,
  rgba(173, 197, 236, 0.7) 30%,
  rgba(255, 150, 150, 0) 75%
);
$color-grd-lightBlue-4: linear-gradient(
  to bottom,
  rgba(173, 197, 236, 0.7) 30%,
  rgba(173, 197, 236, 0) 100%
);
$color-grd-lightBlue-5: linear-gradient(
  to bottom,
  rgba(173, 197, 236, 0.7),
  rgba(255, 150, 150, 0)
);
$color-grd-lightBlue-6: linear-gradient(
  rgba(173, 197, 236, 0.7) 100%,
  rgba(173, 197, 236, 0)
);
$color-grd-lightBlue-7: linear-gradient(
  rgba(173, 197, 236, 0.7) 100%,
  rgba(255, 150, 150, 0)
);
$color-grd-lightBlue-8: linear-gradient(
  to bottom,
  rgba(173, 197, 236, 0.7),
  rgba(255, 150, 150, 0) 44%
);
$color-grd-lightBlue-9: linear-gradient(
  to top,
  rgba(173, 197, 236, 0.7),
  rgba(255, 150, 150, 0)
);
$color-grd-lightBlue-10: linear-gradient(
  rgba(173, 197, 236, 0.7),
  rgba(173, 197, 236, 0)
);
$color-grd-lightBlue-11: linear-gradient(
  to bottom,
  rgba(173, 197, 236, 0.7),
  rgba(173, 197, 236, 0)
);

$color-grd-white-0: linear-gradient(
  270deg,
  rgba(255, 255, 255, 0.5) 0%,
  rgba(255, 255, 255, 0) 100%
);

@mixin d-flex($jc: initial, $al: initial, $fd: row, $gap: 0) {
  display: flex;
  align-items: $al;
  justify-content: $jc;
  flex-direction: $fd;
  gap: $gap;
}

@mixin font-style($fs: normal, $fw: normal, $lh: normal) {
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
}

@mixin sans-family() {
  font-family: "DM Sans", sans-serif;
}

@mixin man-family() {
  font-family: "Manrope", sans-serif;
}
