// @import "src/styles/variable.scss";
@use "styles/variable" as *;

.header {
  border-bottom: 1px solid $color-white-3;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-white-0;
  transition: top 0.3s;
  z-index: 1000;

  .headerContainer {
    @include d-flex($jc: space-between, $al: center);
    padding: 16px 0;
    max-height: 84px;

    .content {
      @include d-flex($gap: 48px, $al: center);

      .logo {
        cursor: pointer;
      }

      .nav {
        @include d-flex($gap: 48px, $al: center);

        .navContent {
          @include sans-family();
          @include font-style($fs: 16px, $fw: 450);
          color: $color-black-nav-0;
          cursor: pointer;

          a {
            color: inherit;
            text-decoration: none;
          }

          &:hover {
            color: $color-green-0;
          }
        }
        .service {
          position: relative;
          height: 84px;
          @include d-flex();
          transition: all 0.3s ease;

          .displayContent {
            @include d-flex($al: center);

            &:hover {
              .serviceDrop svg path {
                fill: $color-green-0;
              }
            }

            .serviceDrop {
              height: 20px;
              width: 20px;
              margin-top: 2px;
            }
          }
          .dropContent {
            position: absolute;
            top: 84px;
            left: -100px;
            z-index: 2;

            .dropContainer {
              width: 352px;
              padding: 16px;
              border-radius: 8px;
              border: 1px solid $color-white-1;
              background: $color-white-0;
              box-shadow: 0px 0px 8px 0px $color-black-1;

              .dropItems {
                @include d-flex($gap: 10px);
                padding: 12px;
                border-radius: 6px;

                &:hover {
                  background: $color-lightBlue-0;
                }

                .imgSection {
                  .imgDropWrapper {
                    width: 24px;
                    height: 24px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .textSection {
                  @include d-flex($fd: column, $gap: 6px);

                  .title {
                    @include sans-family();
                    @include font-style($fs: 16px, $fw: 450, $lh: 28px);
                    color: $color-black-nav-0;
                  }

                  .textContent {
                    @include sans-family();
                    @include font-style($fs: 14px, $fw: 350);
                    color: $color-gray-0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .hamburger {
      position: relative;
    }
    .contact {
      cursor: pointer;

      .contactBtn {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        @include d-flex($gap: 8px);
        padding: 12px 24px;
        border-radius: 36px;
        border: 1px solid $color-white-2;
        background: $color-white-0;
        transition: all 0.3s ease;

        .contactText {
          @include sans-family();
          @include font-style($fs: 16px, $fw: 450, $lh: 28px);
          color: $color-blue-0;
        }

        .animation {
          position: absolute;
          width: 30%;
          top: 0;
          bottom: 0;
          transform: skewY(35deg);
          right: 100%;
          background: $color-grd-darkBlue-0;
          filter: blur(6px);
          z-index: 0;
          transition: all 0.6s ease-out;
        }

        &:hover {
          border: 1px solid $color-blue-0;

          .animation {
            transform: translateX(500%);
          }
        }
      }
    }

    .menuModal {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      top: 76px;
      background-color: $color-black-4;

      .menu {
        border-bottom: 1px solid $color-white-3;
        background: $color-white-0;
        transition: max-height 0.4s ease-out;

        &.menu-active {
          max-height: 282px;
        }
        .menuContainer {
          .menuContent {
            @include d-flex($jc: space-between, $al: center);
            border-bottom: 1px solid $color-white-3;
            background: $color-white-0;

            .topContent {
              @include d-flex($jc: space-between, $al: center);
              padding: 20px;
            }

            .dropContent {
              top: 84px;
              left: -100px;

              .dropContainer {
                .dropItems {
                  @include d-flex($gap: 10px);
                  padding: 20px;

                  .imgSection {
                    .imgDropWrapper {
                      width: 24px;
                      height: 24px;

                      img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                  .textSection {
                    @include d-flex($fd: column, $gap: 8px);

                    .title {
                      @include sans-family();
                      @include font-style($fs: 16px, $fw: 450, $lh: 28px);
                      color: $color-black-nav-0;
                    }

                    .textContent {
                      @include sans-family();
                      @include font-style($fs: 14px, $fw: 350);
                      color: $color-gray-0;
                    }
                  }
                }
              }
            }
            .text {
              p {
                @include sans-family();
                @include font-style($fs: 16px, $fw: 450);
                color: $color-black-nav-0;
              }
            }
          }
          .service {
            @include d-flex($fd: column);

            .topContent {
              .menuImg {
                .menuImgWrapper {
                  width: 24px;
                  height: 24px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
          .offer {
            padding: 20px;
          }
          .contactEdit {
            @include d-flex($jc: center);
            padding: 20px 20px 40px 20px;

            .contactBtn {
              width: 335px;
              @include d-flex($jc: center, $gap: 8px);
            }
          }
        }
      }
    }
  }
}
.hidden {
  top: -125px;
}
.visible {
  top: 0;
}

@media screen and (max-width: 568px) {
  .header {
    .headerContainer {
      padding: 20px 20px;

      .content {
        .logo {
          max-width: 90px;
          height: 30px;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}
