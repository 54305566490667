// @import "src/styles/variable.scss";
@use "styles/variable" as *;

.notFound {
  @include d-flex($jc: center, $al: center);
  height: 100vh;

  .notFoundContainer {
    max-width: 506px;

    .imageSection {
      @include d-flex($jc: center);
    }

    .bottomContent {
      @include d-flex($fd: column, $gap: 24px);

      .notFoundText {
        .oops {
          @include man-family();
          @include font-style($fs: 40px, $fw: 650, $lh: 140%);
          color: $color-black-2;
          text-align: center;
          letter-spacing: -0.8px;
        }

        .notFoundMsg {
          @include sans-family();
          @include font-style($fs: 18px, $fw: 350, $lh: 30px);
          color: $color-black-2;
          text-align: center;
        }
      }

      .goHome {
        @include d-flex($jc: center);
      }
    }
  }
}
